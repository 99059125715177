<template>
  <div class="home overflow-x-hidden">
    <loading :loading="isLoading"></loading>
    <SectionHeader
    :name="objectiveDetail.name"
    :description="objectiveDetail.description"
    :progress="0"
    :loading="isLoading"
    has-back-button
    />

    <!-- SKELETONS LOADING -->
    <b-row class="main-container py-5 px-md-5" v-if="isLoading">
      <b-col class="mb-3" sm="12" md="6" lg="4" xl="3" v-for="n in Array(12)" v-bind:key="n">
        <CategoryCardSkeleton />
      </b-col>
    </b-row>
    <!-- END SKELETONS LOADING -->

    <div class="main-container py-5 px-md-5 overflow-x-hidden">
      <b-row v-if="!hasPills">
        <b-col class="mb-3" sm="12" md="6" lg="4" xl="3"
        v-for="content in children"
        v-bind:key="content.id">
          <CategoryCard
            :img="getUrlImg(content.image)"
            :title="content.name"
            :text="content.description"
            :progress="calculateProgress(content.score, content.total_points)"
            :to="{ name: 'objectiveDetail', params: { id: content.id } }"
          >
          </CategoryCard>
        </b-col>
      </b-row>

      <b-row v-else>
        <b-col class="mb-3" sm="12">
          <PillsTable :items="translatedPills" />
        </b-col>
      </b-row>

      <NoResults v-show="!isLoading" v-if="!hasPills && !hasObjectives" />

    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CategoryCard from '@/components/utils/cards/CategoryCard'
import CategoryCardSkeleton from '@/components/skeletons/CategoryCardSkeleton'

import SectionHeader from '@/components/SectionHeader'

import PillsTable from '@/components/tables/Pills'
import NoResults from '@/components/utils/NoResults'

import ProgressMixin from '@/services/mixins/progress.mixins'
import mapper from '@/services/mixins/mapper.mixins'
import i18nmixin from '@/services/mixins/i18n.mixins'

export default {
  name: 'ObjectiveDetail',
  mixins: [ProgressMixin, mapper, i18nmixin],
  components: { CategoryCard, SectionHeader, PillsTable, CategoryCardSkeleton, NoResults },
  data () {
    return {
    }
  },
  methods: {
    ...mapActions({
      getObjectiveDetail: 'objectives/getObjectiveDetail'
    })
  },
  computed: {
    ...mapGetters({
      originalObjectiveDetail: 'objectives/getObjectiveDetail',
      isLoading: 'objectives/isLoading'
    }),
    objectiveDetail () { return this.translateObject(this.originalObjectiveDetail) },
    translatedPills () { return this.translateObjectArray(this.objectiveDetail.pills) },
    children () { return this.translateObjectArray(this.originalChildren) },
    originalChildren () { return this.objectiveDetail.children || this.objectiveDetail.pills },
    hasPills () { return this.objectiveDetail.pills && this.objectiveDetail.pills.length > 0 },
    hasObjectives () { return this.objectiveDetail.children && this.objectiveDetail.children.length > 0 }
  },
  mounted () {
    this.getObjectiveDetail(this.$route.params.id)
  }
}
</script>
